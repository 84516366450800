import { render, staticRenderFns } from "./modalSeleccionarAvatar.vue?vue&type=template&id=5bbdf67e&scoped=true"
import script from "./modalSeleccionarAvatar.vue?vue&type=script&lang=js"
export * from "./modalSeleccionarAvatar.vue?vue&type=script&lang=js"
import style0 from "./modalSeleccionarAvatar.vue?vue&type=style&index=0&id=5bbdf67e&prod&style=scss&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5bbdf67e",
  null
  
)

export default component.exports